const routingMixin = {
  methods: {
    /**
     * Method to route a user
     * @params { Object } params
     **/
    async redirect(params) {
      await this.$router.push(params);
    },
  },
};

export default routingMixin;
